#root {
  font-family: 'Quicksand', sans-serif;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root p a {
  color: inherit;
  text-decoration: underline;
}

#connect-status {
  height: 120px;
}

#appleid-signin {
  margin-top: 48px;
}
